import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import ScrollToTop from "../utils/scrollToTop";

const Loadable = (Component) => (props) => {
  return (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
};

function AppRoutes() {
  const routes = useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { element: <Home replace />, index: true },
        { path: "generalinformation/:id", element: <GeneralInformation /> },
        {
          path: "documentsdndinformation/:id",
          element: <DocumentsAndInformation />,
        },
        { path: "resultsandacademics/:id", element: <ResultsAndAcademics /> },
        { path: "staff/:id", element: <Staff /> },
        { path: "schoolinfrastructure/:id", element: <SchoolInfrastructure /> },
        { path: "schoollibrary/:id", element: <SchoolLibrary /> },
        { path: "sciencelab/:id", element: <ScienceLab /> },
        { path: "computerlab/:id", element: <ComputerLab /> },
        { path: "activityroom/:id", element: <ActivityRoom /> },
        { path: "physicaltraining/:id", element: <PhysicalTraining /> },
        { path: "preprimaryactivities/:id", element: <PrePrimaryActivities /> },
        { path: "principalsdesk/:id", element: <Principalsdesk /> },
        { path: "events", element: <Events /> },
        { path: "eventdetails/:id", element: <EventDetails /> },
        { path: "admissionguidelines", element: <AdmissionGuidelines /> },
        { path: "admissionform", element: <AdmissionForm /> },
        { path: "chairmansmessage/:id", element: <ChairmansMessage /> },
        { path: "contact", element: <Contact /> },
        { path: "about", element: <About /> },
        { path: "career", element: <Career /> },
        { path: "downloads", element: <Downloads /> },
        { path: "termsandconditions", element: <TermsAndConditions /> },
        { path: "privacypolicy", element: <PrivacyPolicy /> },
        { path: "topbanner", element: <TopBanner /> },
      ],
    },
  ]);

  return routes;
}

export default function RouterComponent() {
  return (
    <Router>
      <ScrollToTop />
      <AppRoutes />
    </Router>
  );
}

// components
const Layout = Loadable(lazy(() => import("../Components/Layout/Layout")));
const Home = Loadable(lazy(() => import("../Components/Home/Home")));
const GeneralInformation = Loadable(
  lazy(() => import("../Components/Cbse/GeneralInformation"))
);
const DocumentsAndInformation = Loadable(
  lazy(() => import("../Components/Cbse/DocumentsAndInformation"))
);
const ResultsAndAcademics = Loadable(
  lazy(() => import("../Components/Cbse/ResultsAndAcademics"))
);
const Staff = Loadable(lazy(() => import("../Components/Cbse/Staff")));
const SchoolInfrastructure = Loadable(
  lazy(() => import("../Components/Cbse/SchoolInfrastructure"))
);
const SchoolLibrary = Loadable(
  lazy(() => import("../Components/Facilities/SchoolLibrary"))
);
const ScienceLab = Loadable(
  lazy(() => import("../Components/Facilities/ScienceLab"))
);
const ComputerLab = Loadable(
  lazy(() => import("../Components/Facilities/ComputerLab"))
);
const ActivityRoom = Loadable(
  lazy(() => import("../Components/Facilities/ActivityRoom"))
);
const PhysicalTraining = Loadable(
  lazy(() => import("../Components/Facilities/PhysicalTraining"))
);
const PrePrimaryActivities = Loadable(
  lazy(() => import("../Components/Activities/PrePrimaryActivities"))
);
const Principalsdesk = Loadable(
  lazy(() => import("../Components/Principalsdesk/PrincipalsDesk"))
);
const Events = Loadable(lazy(() => import("../Components/Events/Events")));
const EventDetails = Loadable(
  lazy(() => import("../Components/Events/EventDetails"))
);
const AdmissionGuidelines = Loadable(
  lazy(() => import("../Components/Admissions/AdmissionGuidelines"))
);
const AdmissionForm = Loadable(
  lazy(() => import("../Components/Admissions/AdmissionForm"))
);
const ChairmansMessage = Loadable(
  lazy(() => import("../Components/Chairmansmesage/ChairmansMessage"))
);
const Contact = Loadable(lazy(() => import("../Components/Contact/Contact")));
const About = Loadable(lazy(() => import("../Components/About/About")));
const Career = Loadable(lazy(() => import("../Components/Career/Career")));
const Downloads = Loadable(
  lazy(() => import("../Components/Downloads/Downloads"))
);
const TermsAndConditions = Loadable(
  lazy(() => import("../Components/About/TermsAndConditions"))
);
const PrivacyPolicy = Loadable(
  lazy(() => import("../Components/About/PrivacyPolicy"))
);
const TopBanner = Loadable(
  lazy(() => import("../Components/Banner/TopBanner"))
);
